import request from '@/utils/request'

export function drawinfos(data) { // 获取邮箱
  return request({
    url: '/safe/infos',
    method: 'get',
    params: data
  })
}

export function setmail(data) { // 设置新邮箱
  return request({
    url: '/safe/setmail',
    method: 'get',
    params: data
  })
}

export function editmail(data) { // 修改邮箱
  return request({
    url: '/safe/editmail',
    method: 'get',
    params: data
  })
}

export function sendmail(data) { // 获取验证码
  return request({
    url: '/safe/sendmail',
    method: 'get',
    params: data
  })
}
export function sendTgcode(data) { // 获取验证码
  return request({
    url: '/safe/sendTgecode',
    method: 'get',
    params: data
  })
}

export function setloginname(data) { // 设置账号
  return request({
    url: '/safe/setloginname',
    method: 'get',
    params: data
  })
}

export function editloginpwd(data) { // 修改登录密码
  return request({
    url: '/safe/editloginpwd',
    method: 'get',
    params: data
  })
}

export function getNewLoginpwd(data) { // 修改登录密码
  return request({
    url: '/safe/getNewLoginpwd',
    method: 'get',
    params: data
  })
}

export function setdrawpwd(data) { // 设置提现密码
  return request({
    url: '/safe/setdrawpwd',
    method: 'get',
    params: data
  })
}

export function editdrawpwd(data) { // 修改提现密码
  return request({
    url: '/safe/editdrawpwd',
    method: 'get',
    params: data
  })
}

export function getNewDrawpwd(data) { // 修改提现密码
  return request({
    url: '/safe/getNewDrawpwd',
    method: 'get',
    params: data
  })
}
